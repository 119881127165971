.header {
    position:absolute;
    width:100%;
    top:0;
    left:0;
    z-index: 999;
    padding: 1.8rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    //transition: all .2s ease-in-out, background-color 0s; 
    transition: all .2s ease-in-out; 
    background-color:rgba(255,255,255,0);
    
    @include desktop-small-below {
        position:fixed; 
        overflow:hidden;
        padding:0; //padding becomes handled on mobile-nav
        transition: none; 
        &.mobile-menu-is-open:not(.is-compressed) {
            height:100vh;
        }
        .is-searching.modal-open &{
            z-index:1080; //Go above search window
            box-shadow:none;
        }
    }



    .desktop-navs {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        @include desktop-small-below {
            width:0;
            height:0;
            overflow-y: scroll;
        }
    }
    .primary-nav,
    .secondary-nav {
        transition:opacity .2s ease-in-out;
    }
    .header__wrapper {
        z-index: 1;
        max-width: 1800px;
        width: 100%;
        margin: 0 auto;
        padding: 0 3rem;
        position: relative;
        display: flex;
        flex-direction: column;
        @include desktop-small-below {
            padding:0; //padding becomes handled on mobile-nav
        }
    }
    a:hover {
        text-decoration:none;
    }
    &.active-meganav {
        position:fixed;
        background-color:$navy-200;
        > .header__wrapper {
            height: 100vh;
            overflow-y: auto;
            @include desktop-small-below {
                height: initial;
                overflow-y: initial;
            }
        }
    }
    &.mobile-menu-is-open {
        @include desktop-small-below {
            .desktop-navs {
                width: 100%;
                height: auto;
                background-color:$navy-900;
                padding-bottom: 30rem;
            }
            > .header__wrapper {
                height:100%;
                overflow-y: scroll;
            }
        }
    }

    &.is-compressing  {
        .secondary-nav {
            opacity:0;
        };
        .primary-nav__logo,
        .primary-nav__link[data-level="0"],
        &.active-meganav .active-megamenu .primary-nav__link[data-level="0"] {
            opacity:0;
        }
    }
    &.is-compressed {
        position:fixed;
        background:rgba(255,255,255,1);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        &:not(.active-meganav){
            > .header__wrapper {
                height:initial;
            }
        }
        > .header__wrapper .desktop-navs{
            flex-direction:row;
            align-items: center;
        }
        .primary-nav {
            order:initial
        }
    }
    &.is-decompressing  {
        background:rgba(255,255,255,0);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0);
        .secondary-nav {
            opacity:0;
        };
        .primary-nav__logo,
        .primary-nav__link[data-level="0"],
        &.active-meganav .active-megamenu .primary-nav__link[data-level="0"] {
            opacity:0;
        }
    }
 
    &::before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        height: 100vh;
        width: 100%;
        background-color: rgba(8, 48, 111, .7);
        z-index: -1;
        pointer-events: none;
        opacity:0;
        // transition: opacity .2s ease-in-out;
    }
    &::after {
        content:"";
        position:absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        background-color:$navy-200;
        z-index: 0;
        opacity:0;
        // transition: opacity .2s ease-in-out;
    }
    &.active-meganav::before,
    &.active-meganav::after {
        opacity:1;
    }
     
}


.primary-nav {
    order:1;
    width:100%;
    transition: all .1s ease-in-out;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;

    h2 {
        color:#fff;
        font-size: 2.6rem;
        margin-bottom:2rem;
        padding-left: 2.4rem;
    }

    // h3 {
    //     color:#fff;
    //     font-size: 2rem;
    //     margin-bottom:2rem;
    //     padding-left: 2.4rem;
    // }

    &__logo {
        transition: opacity .2s ease-in-out;
        img {
            width:100%;
            max-width:300px;
            min-width:140px;
            height:auto;
            // transition: all .2s ease-in-out;
            .is-compressed & {
                max-width:200px;
            }
            @include desktop-small-below {
                display:none; //logo handled in mobile-nav
            }
        }
        &-white {
            display:none;
        }
        .header--dark:not(.active-meganav):not(.is-compressed) & {
            &-white {
                display:block;
            }
            &-color {
                display:none;
            }
            @include desktop-small-below {
                display:none
            }
        }  
        &:hover {
            opacity:0.7;
        }      
    }

    &__main-menu {
        display:flex;
        list-style-type: none;
        margin:0;
        flex: 1;
        @include desktop-small-below {
            width: 100%;
            flex-direction: column;
            padding: 2.5rem;
        }
    }

    &__description {
        font-size:2rem;
        color:#fff;
        // margin-bottom:2rem;
        padding-left: 2.4rem;
        font-weight: 300;
        p{
            color:#fff;
        }
    }

    &__next-level-arrow {
        position:absolute;
        left: -16px;
        top: 3.2rem;
        &[data-level="3"] {
            left: -6px;
            rect  {
                fill: #fff;
            }
        }
    }

    &__close-btn {
        display:none;
        margin-left: auto;
        background: none;
        border: 0;
        font-size: 2.2rem;
        color:$navy-900;
        position: relative;
        top: 4px;
        &:hover {
            cursor:pointer;
        }
        .active-meganav & {
            @include desktop-small-above{
                display:inline-block;
            }
        }
    }

    &__menu {
        display:none;
        box-sizing: border-box;

        &[data-level='1'] {
            position:absolute;
            left:0;
            width:100%;
            top:120px;
            transition: top .2s ease-in-out;
            .is-compressing &,
            .is-compressed & {
                top:80px;
            }
            .is-decompressing & {
                top:120px;
            }
            > div {
                width:25%;
                height: 100%;
                padding:3rem 2.4rem;
                background:$navy;
                border-radius: 5px;
                box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.06);

                &.primary-nav__fixed-right-panel {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 50%;
                    background-color:#fff;
                    z-index:1;
                    height: fit-content;
                    &--three-quarters {
                        width: 75%;
                    }
                    @include desktop-small-below {
                        display:none;
                    }
                    .primary-nav__index-link {
                        font-family: "GTAmerica Extended", sans-serif;
                        color: $navy;
                        text-decoration: none;
                        font-size: 2.2rem;
                        font-weight: 400;
                        display: inline-block;
                        margin: 0 0 1rem 1rem;
                    }
                    .fixed-panel__media-wrapper {
                        display:flex;
                        justify-content: flex-start;
                        align-items: stretch;
                        > div {
                            flex:1;
                            flex-direction: column;
                            margin: 2rem 1.2rem;
                        }
                        > div {
                            > div {
                                opacity:0;
                                transform: translate3d(-20px, 0, 0);
                            }
                            @for $x from 2 through 6 {     
                                &:nth-child(#{$x - 1}) {
                                    > div {
                                        animation-delay: 80ms * ($x - 1);
                                    }
                                }
                            }
                            > div {
                                animation: navigation-cards .4s forwards $bezier-smooth;
                            }
                        }
                        .video-block__summary {
                            display:none;
                        }
                        .event-block {
                            &__summary {
                                display: none;
                            }
                            &__primary a{
                                margin-bottom: 0;
                            }
                        }
                        .news-block {
                            &__summary {
                                display: none;
                            }
                            &__primary a{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        
        &[data-level='2'] {
            position:absolute;
            top:0;
            right:0;
            width:75%;
            padding-right:50%;
            z-index: 1;
            > div {
                padding:3rem;
                background:#fff;
                width:100%;
                height:100%;
                border-radius: 5px;
                box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.06);
            }
        }
        
        &[data-level='3'] {
            overflow:hidden;
            padding:3rem 3rem 3rem 6rem;
            position:absolute;
            top:0;
            right:1px;
            width:66.66%;
            background:#fff;
            border-radius:5px;
            > div {
                height:100%;
            }
            // fake a shadow from L2 
            &::before {
                position:absolute;
                content:"";
                left:0;
                top:0;
                height:100%;
                width:10px;
                box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.03), 0px 3.34221px 33.4221px rgba(0, 0, 0, 0.0215656), 0px 1.7869px 17.869px rgba(0, 0, 0, 0.0178832), 0px 1.00172px 10.0172px rgba(0, 0, 0, 0.015), 0px 0.532008px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 0.221381px 2.21381px rgba(0, 0, 0, 0.00843437);
            }
        }
    }



    &__list {
        &[data-level='3'] {
            height:90%;
            column-count: 2;
            column-fill: auto;
        }
    }

    &__list-item {
        &[data-level='0']{
            margin-left: 5rem;
            @include desktop-small {
                .is-compressed & {
                    margin-left: 4rem;
                }
            }
        }  
        &[data--is-open] > .primary-nav__menu {
            display:block;
        }
        &[data--is-open] > .primary-nav__link {
            @include desktop-small-below {
                .i-plus-thick {
                    opacity:0;
                }
                .i-minus-thick {
                    opacity:1;
                }
            }
        }
        &.devNode {
            display:none !important;
        }
        &[data-level='2'],
        &[data-level='3']{
            opacity:0;
            animation: nav-reveal .2s forwards $bezier-smooth;
            @for $x from 2 through 50 {
                &:nth-child(#{$x - 1}) {
                    animation-delay: 40ms * ($x - 1);
                }
            }
        }
       
    }

    &__link {
        font-family: $font-body;
        position:relative;
        width: 100%;
        display: block;
        text-decoration:none;
        display:flex;
        align-items: center;
        transition: opacity .2s ease-in-out;

        &[data-level="0"]{
            font-size:1.8rem;
            color:$navy;
            padding: 1rem 0;
            font-weight:700;
            .header--dark:not(.active-meganav):not(.is-compressed) & {
                color:#fff;
            }
            .active-meganav & {
                color:$navy-500; 
                opacity:0.7;      
            }
            .active-meganav .active-megamenu & {
                color:$navy;     
                opacity:1;  
            }
            &::after {
                content:"";
                position:absolute;
                width:100%;
                height:0;
                bottom:5px;
                left:0;
                transition: height .2s ease-in-out;
                .primary-nav__list-item[data--is-active] > &  {
                    background-color:$navy;
                    height:2px;
                    .header--dark:not(.active-meganav):not(.is-compressed) & {
                        background-color:#fff;
                    }
                }
                .active-megamenu & {
                    height:3px;
                    background-color:$orange;
                    opacity:1;
                }
                .active-meganav .primary-nav__list-item[data--is-active] > &  {
                    opacity:0.3;
                }
                .active-meganav .primary-nav__list-item[data--is-active].active-megamenu > &  {
                    opacity:1;
                } 
                @include desktop-small-below {
                    display:none;
                }
            }
        }
    
        &[data-level="1"]{
            margin: 0.5rem 0;
            padding: 1.5rem 2rem;
            color:#fff;
            font-size:2.1rem;
            font-weight:400;
            border-radius: 5px;
            transition: all .2s ease;
            [data--is-open] > &, 
            header:not(.mobile-menu-is-open) &:hover  {
                background-color: rgba(255,255,255,.15);
            }
            .primary-nav__link-icon {
                color:$orange;
                margin-right:1rem;
            }
            .primary-nav__link-arrow {
                color: $navy-500;
                [data--is-open] > & {
                    color:$orange;
                }
            }    
            header:not(.mobile-menu-is-open) &:hover {
                .primary-nav__link-arrow {
                    color:$orange;
                }   
            }    
        }
    
        &[data-level="2"]{
            padding: 0.8rem 1rem;
            font-size:1.7rem;
            color:$navy-500;
            font-weight:400;
            line-height: 1.5;
            transition: all .2s ease;
            &.primary-nav__link[data--is-active] {
                font-weight:700;
                color: $navy;
            }
            i{
                font-size: 1.4rem;
            }
            [data--is-open] > &,
            header:not(.mobile-menu-is-open) &:hover  {
                color:$navy;
                background-color: $navy-200;
            }
        }
    
        &[data-level="3"]{
            padding: 0.5rem 1rem;
            font-size:1.7rem;
            color:$navy;
            font-weight:400;
            line-height: 1.5;
            &.primary-nav__link[data--is-active] {
                font-weight:700;
                color:$navy;
            }
            header:not(.mobile-menu-is-open) &:hover {
                text-decoration: underline;
                color:$navy-900;
            }
            [data--is-open] > & {
                font-weight:700;
            }
        }
    }

    &__link-title {
        @include desktop-small-below {
            width:100%;
            margin-right: 5rem; //leaves more room for + touch area
            font-size:2.4rem;
            [data--is-open] > .primary-nav__link > & {
                font-weight:bold;
                color:#fff;
            }
        }
    }

    &__link-icon {
        display:none;
        .primary-nav__list-item[data-name="Capabilities"] &,
        .primary-nav__list-item[data-name="Solutions"] & {
            display:inline-block;
        }
    } 

    &__link-arrow {
        margin-left:auto; 
    }

    &__index-link {
        &[data-level="1"]{
            // color:#fff;
            // margin-bottom:2rem;
            // display: inline-block;
            // display: inline-block;
            // padding: 1rem 0;
            font-size:2rem;
            padding-left: 2.4rem;
            text-decoration: none;
            overflow:hidden;
            // display:flex;
            // align-items:center;
            i {
                color:$orange;
                // margin-left:1rem;
            }
            header:not(.mobile-menu-is-open) &:hover { 
                opacity:0.8;
            }
        }
        &[data-level="2"],
        &[data-level="3"]{
            font-family: $font-heading;
            color:$navy;
            text-decoration: underline;
            font-size:2.2rem;
            font-weight: 400;
            display:inline-block;
            margin: 0 0 1rem 1rem;
            &[href=""] {
                text-decoration:none;
            }
            header:not(.mobile-menu-is-open) &:hover {
                color:$navy-900;
            }
        }
        &-mobile-prompt {
            display:none;
            @include desktop-small-below {
                display:inline-block;
            }
        }
    }

    &__mobile-plus {
        display:none;
        position:relative;
        margin-left: auto;  
        i {
            color:$orange;
            font-size:2.4rem;
        }
        .i-plus-thick {
            position:relative;
        }
        .i-minus-thick {
            position:absolute;
            top:0;
            right:0;
            opacity:0;
        }
        @include desktop-small-below {
            display:flex;
        }
    }

     // Mobile Version
    @include desktop-small-below {
        display:none;
        order:initial;
        .mobile-menu-is-open & {
            display:flex;
        }
        
    }
}


// Nav items cannot just use transitions because parent uses display:none; so they are, as children, are untransitionable when triggered by display swaps
@keyframes nav-reveal {
    0% {
        opacity:0;
        margin-left: -1rem;
    }
    100% {
        opacity:1;
        // transform:translateX(0);
        margin-left:0;
    }
}