%base-block{
	background-color:#fff;
  border-radius:5px;
	box-shadow:$shadow-300;
	width:100%;
	margin-bottom:2rem;
	display: flex;
  	@include el-size(third, quarter, sixth) {
  		flex-direction:column;
  	}
  	@include el-size(half, two-thirds, three-quarters, full) {
  		flex-direction:row;
		@include phone-large-below{
			flex-direction:column;
		}
  	}
	@include phone-large-below{
		flex-direction:column;
	}
}
%base-block__secondary{
	padding:2rem 2rem 0;
	// padding:0;
	width:100%;
	position:relative;
    flex-shrink: 0;
    @include el-size(half, two-thirds, three-quarters, full) {
    	padding-bottom:2rem;
    	@include column(6);
      @include desktop-large-below {
        @include column(6);
      }
      @include phone-large-below{
       // padding-bottom:0;
      	@include column(12);
      }
    }
}
%base-block__primary{
	padding:2rem;
	width:100%;
	position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
	//[class$="title"]{
	//	@extend %heading-five;
	//}
	p{
		font-size:1.8rem;
		font-weight: 200;
		color:$navy-500;
		margin-bottom:1rem;
		@include phone-large-below {
			font-size:2.3rem;
		}
	}
    @include el-size(third, quarter, sixth) {
      justify-content: flex-start;
    }
    @include el-size(full, half) {
      @include desktop-large-below {
        @include column(6);
      }
      @include phone-large-below{
      	@include column(12);
      }
    }
}

%base-block__img{
	border-radius: 5px;
	width: 100%;
}

%base-tag{
    font-size:1.5rem;
    background:$navy-200;
    border-radius:5px;
    padding: 0.7rem 1.5rem;
    color:$navy;
    width:max-content;
	line-height: 1.6;
	@include phone-large-below {
		font-size:2rem;
	}
}
%base-date{
	font-size:1.5rem;
	padding:1.5rem 1.5rem 1.5rem 0;
	color:$navy-500;
	line-height: 1.6;
	@include phone-large-below {
		font-size:2rem;
	}
}